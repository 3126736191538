<template>
  <div>
    <div
      v-for="item in items"
      :key="item.id"
      class="cart-list__item">
      <CartItem
        :item="item"
        :hideSelectors="shouldHideSelectors(item)"
        :hideActions="shouldHideActions(item)"
        :canAddUnits="!hasReachedMaxUnits(item)" />
      <div
        v-if="item.description"
        class="textError">
        <p>* {{ $t(item.description) }}</p>
      </div>
      <div
        v-if="item.custom_items && Array.isArray(item.custom_items) && item.custom_items.length"
        class="product_customization--minicart">
        <div class="product_customization--minicart__header">
          <div class="product_customization--minicart__header__title">
            {{ $t('product.customization.title') }}
          </div>
          <div>
            {{
              item.custom_items[0].price === '0 EUR'
                ? $t('cart.free_shipping')
                : item.custom_items[0].price?.replace('EUR', '€')
            }}
          </div>
        </div>
        <div class="product_customization--minicart__footer">
          {{ item.custom_items[0].custom_service_data?.text }} ,
          {{ item.custom_items[0].custom_service_data?.color?.name }}
        </div>
      </div>
      <div
        v-for="gift in item.gifts"
        :key="gift.id">
        <CartGift :gift="gift" />
      </div>
      <div
        v-for="cost in item.costs"
        :key="cost.id"
        class="cart-list__item__related_service">
        <CartItem
          :hideSelectors="true"
          :hideActions="!item?.mandatory"
          :item="cost"
          :canAddUnits="!hasReachedMaxUnits(item) && item.units > cost.units" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import CartItem from './CartItem.vue';
  import CartGift from './CartGift.vue';
  import useCartActionsLogic from 'CommonComposables/useCartActionsLogic';

  defineProps({
    items: { type: Array, required: true }
  })

  const { hasReachedMaxUnits } = useCartActionsLogic()
  function shouldHideActions(currentItem) {
    return currentItem.data_layer?.conf_type === 'motiondisplay' || currentItem?.is_cost_installation_service;
  }
  function shouldHideSelectors(currentItem) {
    return currentItem.data_layer?.conf_type === 'motiondisplay' || !!currentItem.data_layer?.motion_childs;
  }
</script>
