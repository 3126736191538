<template>
  <div class="wrapper_megadrop">
    <a
      class="skip2content"
      tabindex="0"
      href="#main-content"
      :aria-label="$t('header.skip_to_main_content')">
      {{ $t('header.skip_to_main_content') }}
    </a>
    <button
      id="burger-handler"
      :class="burgerClass"
      data-synth="LOCATOR_BURGER_BUTTON"
      tabindex="0"
      aria-haspopup="menu"
      :aria-controls="`megadrop_1_0`"
      :aria-expanded="open"
      :aria-label="$t('megadrop.categories_menu')"
      @click.prevent="handlerMegadrop">
      <i class="icon" />
    </button>
    <div
      v-show="open"
      id="megadrop"
      ref="mainMegadrop"
      @click.prevent="handlerMegadrop">
      <div
        v-if="open"
        :key="`megadrop_container_0`"
        :class="currentMegadropStyle">
        <div
          ref="slideMenu"
          class="slide-menu"
          @click.stop>
          <MegadropList
            :id="`megadrop_1_${megadropListId}`"
            :key="`megadrop_1_${megadropListId}`"
            :currentlySelectedIndex="currentlySelectedIndex"
            :menu-list="megadrop"
            :level="1"
            :opened="true"
            aria-labelledby="burger-handler"
            class="megadrop__list"
            height="auto"
            role="menu"
            @first-level-mounted="handleMegadropMounted"
            @megadrop-list-mounted="pushAnalytics" />
          <Transition name="slide-fade">
            <MegadropMainArea
              v-if="shouldShowMainArea"
              class="megadrop__main_area"
              :menu="selectedFirstLevelMenu"
              :parentSelectedIndex="currentlySelectedIndex" />
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
import { mapGetter } from 'CommonUtils/store/state.js';
import MegadropAnalytics from 'CommonUtils/analytics/megadrop.analytics.js';

export default {
  name: 'Megadrop',
  components: {
    MegadropList: defineAsyncComponent(() => import('./MegadropList.vue')),
    MegadropMainArea: defineAsyncComponent(() => import('./MegadropMainArea.vue')),
  },

  setup() {
    const { isMobileDevice } = useNavigatorDetect();
    const megadrop = mapGetter('page/getMegadrop');
    const pageHierarchy = mapGetter('page/getCategories');
    const open = ref(false);
    const firstLevelWidth = ref(0);
    const megadropListId = ref(0);
    
    const currentlySelectedIndex = computed(() => {
      return megadrop?.value?.findIndex((megadrop) => megadrop.current);
    });

    const selectedFirstLevelMenu = computed(() => {
      return megadrop?.value?.filter((megadrop) => megadrop.current)?.[0];
    });

    const shouldShowMainArea = computed(() => {
      return (selectedFirstLevelMenu.value && !isMobileDevice.value) || false;
    });

    const burgerClass = computed(() => {
      return {
        'nav-burger': true,
        active: open.value,
      };
    });

    const currentMegadropStyle = computed(() => {
      return {
        new_megadrop: true,
        _open: open.value,
      };
    });

    const handlerMegadrop = () => {
      open.value = !open.value;
      if (!open.value) {
        document.body.style.overflowY = 'auto';
        clearAllSelectedNodes(megadrop.value);
        initializePreselectedOptions(megadrop.value);
        megadropListId.value += 1;
        document.getElementById('uniformsSearchBarContainer')?.classList.remove('hidden');
      } else {
        MegadropAnalytics.sendOpenMegadrop(pageHierarchy?.value?.[0]?.name ?? 'Home');
        document.body.style.overflowY = 'hidden';
        document.getElementById('uniformsSearchBarContainer')?.classList.add('hidden');
      }
    };

    const scrollToTop = () => {
      if (slideMenu?.value?.scrollTo) {
        slideMenu.value.scrollTo({ top: 0, left: 0 });
      }
    }
    provide('scrollToTop', scrollToTop);

    const initializePreselectedOptions = (megadrop) => {
      const currentlySelectedNode = megadrop?.find((menu) => menu.preselected_by_default);
      
      if (currentlySelectedNode) {
        currentlySelectedNode.current = true;
        if (currentlySelectedNode?.children?.length) {
          initializePreselectedOptions(currentlySelectedNode.children);
        }
      }
    };

    const clearAllSelectedNodes = (megadrop) => {
      const currentlySelectedNode = megadrop?.find((menu) => menu.current);
      if (currentlySelectedNode) {
        currentlySelectedNode.current = false;
        if (currentlySelectedNode?.children?.length) {
          clearAllSelectedNodes(currentlySelectedNode.children);
        }
      }
    };

    const handleMegadropMounted = (menuWidth) => {
      firstLevelWidth.value = menuWidth + 'px';
    };

    const pushAnalytics = () => {
      let megadropPromos = null;
      if (selectedFirstLevelMenu.value) {
        megadropPromos = MegadropAnalytics.checkMegadropPromos([selectedFirstLevelMenu.value]);
      }
      MegadropAnalytics.sendPromotionPushMegadropInitial(megadropPromos);
    };
    const slideMenu = ref(null);
    const goToTopSlideMenu = () => {
      slideMenu?.value?.scrollTo(0, 0);
    };

    provide('pageCategoryName', pageHierarchy.value?.[0]?.name);

    return {
      burgerClass,
      currentMegadropStyle,
      currentlySelectedIndex,
      firstLevelWidth,
      goToTopSlideMenu,
      handleMegadropMounted,
      handlerMegadrop,
      megadrop,
      megadropListId,
      open,
      pushAnalytics,
      shouldShowMainArea,
      selectedFirstLevelMenu,
      slideMenu,
    };
  },
};
</script>

<style lang="less" scoped>
@import 'CommonLessFiles/variables/screen.less';
@import 'CommonLessFiles/variables/zIndex.less';
@import 'LessFiles/design_system/colors.less';

@_burger_width: 20px;

.wrapper_megadrop {
  @media @desktop {
    margin-left: 1rem;
  }
}

.new_megadrop {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: @_zModal;
  background-color: white;
  .slide-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: #fff;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    @media @desktop {
      flex-direction: row;
      padding-right: 1.75rem;
      padding-bottom: 1.75rem;
      padding-left: 1.75rem;
      overflow: hidden;
      .megadrop__main_area {
        width: 100%;
      }
    }
  }

  .highlighted > a,
  .highlighted > span {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: bold;
    &:first-child {
      margin-top: 0em !important;
    }
  }

  .line_break {
    padding-bottom: 1.65em;
  }

  .row_break {
    break-after: column;
  }

  .special_red > a,
  .special_red > span {
    font-size: 13px;
    font-weight: bold;
    color: #e2001a;
  }

  .special_color > a,
  .special_color > span {
    font-size: 13px;
    color: var(--special-color) !important;
  }

  .special_bold > a,
  .special_bold > span {
    font-weight: bold !important;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
 
