<template>
  <div
    v-if="showHeader"
    id="nav-container"
    class="nav-container"
    :class="{ nav_home: isHome && ACTIVE_TRANSPARENCY }">
    <div
      id="nav"
      ref="nav"
      class="nav">
      <div class="topbar-container">
        <Megadrop />
        <LazyHydrationWrapper :never="true">
          <Logo logo-class="" />
        </LazyHydrationWrapper>
        <SearchBarLink
          v-if="isNewSearchExperience"
          @show-search="handlerShowSearch" />
        <SearchBar
          v-else
          class="nav__search-bar" />
        <div class="topbar">
          <CSC
            v-if="isCSC"
            :link="redirectUrlBasket" />
          <Popover
            border-color="#F2F2F2"
            background-color="#F2F2F2">
            <template #target>
              <ActionIconLink
                v-if="!isLogged"
                :link="loginUrl"
                icon="i-profile" />
              <ActionIconSlot
                v-else
                type-class="-user"
                icon="i-profile i-profile--active"
                animation="right-slide"
                :modal-name="icons.USER">
                <LoginLayout />
              </ActionIconSlot>
            </template>
            <template #info>
              <LoginLayout short />
            </template>
          </Popover>
          <LoyaltyPointsLabel
            v-if="showLoyaltyPoints"
            class="only_desktop" />
          <div class="wrapper__icon_wishlist">
            <ActionIconLink
              class="wrapper_link"
              :link="wishlistUrl"
              :icon="hasItemsInWishList ? 'i-wishlist-filled' : 'i-wishlist'"
              :tooltip="siteBar && siteBar.labels && siteBar.labels.wishlist ? siteBar.labels.wishlist : ''" />
            <TransitionWishList :show="hasItemsInWishList && !shouldShowAnimation" />
            <TransitionWishList :show="isAdded" />
          </div>
          <ActionIconSlot
            :show-label="false"
            :show-only-modal="false"
            :show-badge="true"
            :modal-name="icons.MARKER"
            :should-redirect="shouldRedirectToBasketDesktop"
            :link="redirectUrlBasket"
            type-class="-market"
            class="only_desktop"
            icon="i-market-basket"
            animation="right-slide"
            :tooltip="$t('header.view_cart')"
            :disabled-scroll="true">
            <Cart />
          </ActionIconSlot>
          <ActionIconLink
            :should-redirect="shouldRedirectToBasketMobile"
            :show-badge="true"
            :link="redirectUrlBasket"
            class="only_mobile"
            icon="i-market-basket"
            :tooltip="$t('header.view_cart')" />
        </div>
      </div>
    </div>
    <ActionIconSlot
      :show-label="false"
      type-class="-speech-desktop"
      icon="i-search"
      :show-only-modal="true"
      :disabled-scroll="true"
      :modal-name="icons.SEARCH_SPEECH">
      <SearchSpeech />
    </ActionIconSlot>
    <CampaignsSite v-if="isCampaignsSiteMode" />
  </div>
</template>

<script>
import Megadrop from './Megadrop/Megadrop.vue';
import Logo from './Logo.vue';
import TransitionWishList from 'CommonComponents/Layout/Header/TransitionWishList.vue';
import ActionIconLink from 'CommonComponents/Layout/Header/ActionIconLink.vue';
import ActionIconSlot from 'CommonComponents/Layout/Header/ActionIconSlot.vue';
import LoyaltyPointsLabel from 'Components/Loyalty/LoyaltyPointsLabel.vue';
import Cart from 'CommonComponents/Cart/Cart.vue';
import Popover from 'CommonComponents/Popover/Popover.vue';

import SearchBarLink from 'Components/Search/SearchBarLink.vue';
import SearchBar from 'CommonComponents/Search/SearchBar.vue';

import ICON_NAMES from 'CommonUtils/iconNames.js';
import { LazyHydrationWrapper } from 'vue3-lazy-hydration';
import UtilExposer from 'CommonUtils/exposer.js';

const ACTIVE_TRANSPARENCY = false;

import { onMounted, computed, defineAsyncComponent } from 'vue';
import { mapGetter, mapGetters, mapActions } from 'CommonUtils/store/state.js';
import useCart from 'CommonComposables/cart.js';
import useLogin from 'CommonComposables/login.js';
import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';

export default {
  name: 'HeaderComponent',
  components: {
    ActionIconLink,
    ActionIconSlot,
    CampaignsSite: defineAsyncComponent(() => import('./CampaignsSite/CampaignsSite.vue')),
    CSC: defineAsyncComponent(() => import('./CSC.vue')),
    Cart,
    LazyHydrationWrapper,
    LoginLayout: defineAsyncComponent(() => import('./Login/LoginLayout.vue')),
    Logo,
    LoyaltyPointsLabel,
    Megadrop,
    Popover,
    SearchBarLink,
    SearchSpeech: defineAsyncComponent(() => import('CommonComponents/Search/Voice/SearchSpeech.vue')),
    TransitionWishList,
    SearchBar,
  },
  props: {
    isHome: { type: Boolean, required: false, default: false },
    showHeader: { type: Boolean, required: true, default: true },
  },
  emits: ['showSearch'],
  setup(props, { emit }) {
    const icons = ICON_NAMES;
    const isCampaignsSiteMode = inject('isCampaignsSiteMode', false);

    // Composables
    const { sendGetCart } = useCart();
    const { isLogged, loginUrl, wishlistUrl, siteBar } = useLogin();
    const { isDesktopDevice } = useNavigatorDetect();
    // Actions
    const { initMountCart } = mapActions('cart', { initMountCart: 'initMountCart' });

    // Computed
    const { isServer, internals, homeDomain, isNewSearchExperience } = mapGetters('page', {
      isServer: 'isServer',
      internals: 'getInternals',
      homeDomain: 'getHomeDomain',
      isNewSearchExperience: 'getIsNewSearchExperience',
    });
    const { totalItems, redirectUrlBasket, showLoyaltyPoints, isCSC } = mapGetters('cart', {
      redirectUrlBasket: 'getRedirectUrlBasket',
      showLoyaltyPoints: 'showLoyaltyPoints',
      totalItems: 'getTotalItems',
      isCSC: 'getIsCSC',
    });
    const { isAdded, getTotalWishlist } = mapGetters('wishlist', {
      isAdded: 'getIsAdded',
      getTotalWishlist: 'getTotalCount',
    });
    const statusModalSearch = mapGetter('uiState/getStatusSearch');

    const shouldRedirectToBasketDesktop = computed(() => {
      const isEmptyBasket = totalItems === 0;
      return isEmptyBasket;
    });

    const shouldRedirectToBasketMobile = computed(() => {
      const internalsUrl = internals && internals.value?.req && internals.value.req.url;
      if (internalsUrl) return !internalsUrl.includes(redirectUrlBasket);
      return false;
    });

    const shouldShowAnimation = computed(() => {
      if (isServer) return false;
      return document.referrer.includes(homeDomain);
    });

    const hasItemsInWishList = computed(() => {
      if (!isLogged || isServer) return false;
      const hasWishListCookie = !!internals.value.req.cookies['product_wish_list_ids'].trim();
      if (hasWishListCookie) {
        const decodeCookie = JSON.parse(decodeURIComponent(!!internals.value.req.cookies['product_wish_list_ids']));
        if (!decodeCookie) return;
        const hasDecodeItems = !!Object.keys(decodeCookie).length;
        // ? Si el usuario tiene productos en la store de la wishlist,
        // entonces mostramos el fill icon.
        if (!!getTotalWishlist && hasDecodeItems) return hasDecodeItems;
      }
      // ? Si el usuario no tiene productos en la cookie de wishlist
      // pero añade un producto a la wishlist, entonces mostramos el fill icon.
      if (getTotalWishlist) return true;
      return false;
    });

    const handlerShowSearch = () => {
      emit('showSearch');
    };

    onMounted(() => {
      UtilExposer(['initialize'], 'cart', sendGetCart);
      initMountCart();
      sendGetCart();
    });

    return {
      ACTIVE_TRANSPARENCY,
      hasItemsInWishList,
      icons,
      isAdded,
      isCampaignsSiteMode,
      isCSC,
      isDesktopDevice,
      isLogged,
      loginUrl,
      redirectUrlBasket,
      shouldRedirectToBasketDesktop,
      shouldRedirectToBasketMobile,
      shouldShowAnimation,
      showLoyaltyPoints,
      handlerShowSearch,
      siteBar,
      statusModalSearch,
      wishlistUrl,
      isNewSearchExperience,
    };
  },
};
</script>
