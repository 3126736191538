<template>
  <div>
    <div class="cart-list__item">
      <div class="basket__wrapper">
        <CartImage :item="item" />

        <div class="description__wrapper">
          <Brand
            v-if="isValidBrand"
            :brand="item.brand"
            :className="'cart'" />

          <a
            class="title"
            :href="PdpRedirect.goToPDP(item)">
            {{ item.title }}
          </a>

          <Price
            v-if="!item.must_hide_price"
            :item="item" />

          <CartVariants
            :isDisabledPlusBtn="!canAddUnits"
            :hidePlusAndMinButtons="!!(item.costs?.length || hideSelectors)"
            :hideSelectors="Boolean(item.custom_items)"
            :item="item" />
        </div>

        <CartActionsMiniBasket
          v-if="!hideActions"
          :item="item" />
      </div>
      <div
        v-if="item.description"
        class="textError">
        <p>* {{ $t(item.description) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue'
  import PdpRedirect from 'CommonUtils/pdpRedirect.js';
  import ITEM_TYPES from 'CommonUtils/itemTypes.js';

  import CartImage from './UI/CartImage.vue';
  import Price from 'CommonComponents/Price/Price.vue';
  import CartVariants from './UI/CartVariants.vue';
  import CartActionsMiniBasket from 'Components/Checkout/CartActionsMiniBasket.vue';
  import Brand from 'CommonComponents/Product/Brand.vue';

  const props = defineProps({
    hideSelectors: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false },
    item: { type: Object, required: true },
    canAddUnits: { type: Boolean, default: true },
  });
  const isValidBrand = computed(() => props.item.brand && props.item.brand.name && props.item.type !== ITEM_TYPES.ECI_PRIME)
</script>
