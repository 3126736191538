import { mapGetters, mapActions } from 'CommonUtils/store/state';
import AppEvents from 'CommonUtils/appEvents';
import CART_ACTION_TYPES from 'CommonUtils/cartActionTypes';
import UtilsCart from 'CommonUtils/formatCartObject';
import BTN_PLUS_DISABLED_TYPES from 'CommonUtils/btnPlusDisabledTypes';

export default function useCartActionsLogic() {

  const {
    isLoadingCart,
    getFullCartData,
    getTotalItems,
    getApiResponse,
    isEciCardError,
    hasCouponError,
    isLogged,
    getUser,
    getGuestEmail
  } = mapGetters('card', [
    { isLoadingCart: 'getLoadingStatus' },
    'getFullCartData',
    'getTotalItems',
    'getApiResponse',
    'isEciCardError',
    'hasCouponError',
    'isLogged',
    'getUser',
    'getGuestEmail'
  ]);

  const { internals, isApp } = mapGetters('page', [
    { internals: 'getInternals' },
    'isApp'
  ]);

  const {
    getCart,
    deleteItemByCart,
    postCart,
    postCouponCode,
    putCart,
    showExpiredCartError,
    processToCheckout,
    postCartVpr
  } = mapActions('cart', [
    'getCart',
    'deleteItemByCart',
    'postCart',
    'postCouponCode',
    'putCart',
    'showExpiredCartError',
    'processToCheckout',
    'postCartVpr'
  ]);

  function hasExpiredCartQuery() {
    const expiredQueryValue = internals?.value?.req?.query?.expired;
    const hasEmptyBasket = getTotalItems.value < 1;
    const isExpiredQueryValid = expiredQueryValue === 'true';

    if (hasEmptyBasket && isExpiredQueryValid) {
      showExpiredCartError({
        status: true,
        msg: this.$i18n.t('checkout.errors.EXPIRED_CART')
      });
    }
  }
  function sendPutCart(options) {
    putCart(options);
  }
  function emptyCart() {
    sendPutCart({});
    if (isApp.value) AppEvents.messageEmptyCart();
  }
  function sendGiftTicketCart(options) {
   postCart(options);
  }
  async function sendGetCart() {
    const isModal = internals?.value?.req?.query?.modal
    if(isModal) return;
    await getCart();
    hasExpiredCartQuery();
    if (!isApp.value) return;

    AppEvents.messageSessionCartUpdate();
    AppEvents.messageSendUnits(getTotalItems.value);
  }
  async function sendPostToCart(cartItems, is_from_checkout, successAction, vprPostalCode) {
    if (vprPostalCode) {
      return postCartVpr({
        ...cartItems,
        is_from_checkout,
        successAction,
        vprPostalCode,
      });
    }
    return postCart({
      ...cartItems,
      is_from_checkout,
      successAction
    });
  }
  function sendPostCartToApp(type) {
    const fullResponse = getApiResponse.value;
    if (fullResponse.error) return;

    const data = fullResponse.response;
    data[type] = true;
    AppEvents.messageUpdateCart(data);
    AppEvents.messageSendUnits(getTotalItems.value);
  }
  async function sendUpdateItemCart(
    payload,
    is_from_checkout,
    successAction = CART_ACTION_TYPES.BASKET,
    vprPostalCode = undefined
  ) {
    const isFromCheckout = Boolean(is_from_checkout);
    const cartItems = {
      ...(payload.line_items?.[0] && { line_items: payload.line_items }),
      ...(payload.free_gift_skus?.[0] && { free_gift_skus: payload.free_gift_skus}),
      ...(payload.insurance_items?.[0] && { insurance_items: payload.insurance_items}),
    };
    if (isApp.value) {
      if (!isLogged.value) return AppEvents.messageNeedLogin();
      const result = await sendPostToCart(
        cartItems,
        isFromCheckout,
        successAction,
        vprPostalCode
      );
      await sendPostCartToApp('add_item');
      return result;
    }
    return sendPostToCart(
      cartItems,
      isFromCheckout,
      successAction,
      vprPostalCode
    );
  }
  function addToCartAMV(data) {
    Android.addToCartUnits(data.reference, data.gtin, data.url, data.units);
  }
  async function handleBuyLaterAddToCart(items) {
    if (isApp.value) {
      if (!isLogged.value) return AppEvents.messageNeedLogin();
      await postCart(items);
      return sendPostCartToApp('add_item');
    }
    await postCart(items);

  }
  async function sendDeleteItemCart(line_items, is_from_checkout) {
    if (isApp.value) {
      if (!isLogged.value) return AppEvents.messageNeedLogin();
      await deleteItemByCart({
        line_items: line_items,
        is_from_checkout: is_from_checkout
      });
      return sendPostCartToApp('remove_item');
    }
    await deleteItemByCart({
      line_items: line_items,
      is_from_checkout: is_from_checkout
    });
  }
  function hasReachedMaxInsuranceUnits(item) {
    return UtilsCart.isInsurance(item) && !UtilsCart.canAddInsuranceItem(getFullCartData.value.items, item.insurable_ref);
  }
  function hasReachedMaxUnits(item) {
    return item.description === BTN_PLUS_DISABLED_TYPES.MAX_UNITS || hasReachedMaxInsuranceUnits(item);
  }

  return {
    // Data
    isLoadingCart,
    getFullCartData,
    getTotalItems,
    getApiResponse,
    isEciCardError,
    hasCouponError,
    isLogged,
    getUser,
    getGuestEmail,
    internals,
    isApp,

    // Methods
    addToCartAMV,
    emptyCart,
    handleBuyLaterAddToCart,
    hasExpiredCartQuery,
    hasReachedMaxUnits,
    hasReachedMaxInsuranceUnits,
    postCouponCode,
    processToCheckout,
    sendPostCartToApp,
    sendDeleteItemCart,
    sendUpdateItemCart,
    sendGetCart,
    sendGiftTicketCart,
  }
}